
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import { CouponsKsManageAPI } from "@/common/api/couponManage/couponList_ks";
// import { CouponTimeDurationManageAPI } from "@/common/api/couponTimeDuration";

// import MerchantApi from "@/common/api/merchant";
// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import {
  shareConfirmOptions,
  shareShopType2ch,
  defaultWeekList
} from '@/common/config'

// 暴露的API 列表
export const pageApiList = getPageAPIs(CouponsKsManageAPI)

const defaultForm = {
  name: "", // 名称

  shop_name: "", // 店铺名称

  third_party_id: "", // 快手ID
}

const defaultAllTimeTypes: any = [
  {
    label: '通用',
    value: 0
  },
  {
    label: '其他时间',
    value: 1
  }
]

const defaultForms = {
  name: "",
  // origin_price: "",
  // sale_price: "",
  shop_type: 1,
  expired_days: "",
  kuaishouId: "",
  quantity: '',
  timeDuration: "",
  pool_table_category_ids: [],
  // coupons_time_id: "",

  time_type: 0,
  begin: '00:00',
  end: '00:00',
  limit_day: [],

  venue_id: "",
  venues: [],

  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};

const couponDialogTitle2ch: any = {
  "new-coupon": "新增卡券",
  "edit-coupon": "编辑卡券",
};

// 最大有效期
const maxExpiredDays = 9999;

// 最大时长 单位分钟
const maxTimeDuration = 24 * 60;


// coupon 卡券分属类别
const couponShopType2ch: any = [
  ...shareShopType2ch
]

@Component({
  filters: {
    showCouponShopTypeName(shop_type: number) {
      return couponShopType2ch[shop_type] || ''
    }
  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class CouponsManage extends Vue {
  private form: any = {
    // couponId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  private shopTypeList: any[] = [
    {
      label: '台球',
      value: 1
    },
    {
      label: '棋牌',
      value: 2
    }
  ]

  private couponsDyManageApi = new CouponsKsManageAPI();
  // private couponTimeDurationAPI = new CouponTimeDurationManageAPI();

  private chooseWeekDayList: any[] = []

  private limitTimeTypes: any[] = [
    ...(defaultAllTimeTypes || [])
  ]

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };

    this.chooseWeekDayList = [
      ...this.deepLoopUtil.deepCopy(defaultWeekList, false)
    ]

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {
    this.getCoupons();

    // this.refreshCouponTimeDurations();

    this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getCoupons();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getCoupons();
  }

  private tableData: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getCoupons();
  }

  // private allCouponTimeDurations: any[] = [];

  // private refreshCouponTimeDurations() {
  //   return new Promise((resolve, reject) => {
  //     this.couponsDyManageApi.getCouponTimeDurations(
  //       {
  //         page: 1,
  //         limit: 999999,
  //       },
  //       (res: any) => {
  //         const { data: allCouponTimeDurationList } = (
  //           res ? res.data : {}
  //         ) as any;

  //         this.allCouponTimeDurations = allCouponTimeDurationList || [];

  //         resolve(allCouponTimeDurationList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  private allTableCategoryList: any[] = [];

  private refreshTableClass() {
    return new Promise((resolve, reject) => {
      this.couponsDyManageApi.poolTableCategory(
        {},
        (res: any) => {
          const { list: allTableCategoryList } = (res ? res.data : {}) as any;
          // this.tableData = res.data.list;
          // this.tablePage.totalCount = res.data.count;

          this.allTableCategoryList = allTableCategoryList;

          resolve(allTableCategoryList);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  private addOrUpdateCouponTitle: string = "时长卡";

  private dialogFormVisible: boolean = false;

  // 拉起卡券弹框
  private handleWakeCouponDialog(mode: string) {
    // if (mode == 'new-coupon') { // 新增卡券

    // }
    this.couponDialogMode = mode;
    this.addOrUpdateCouponTitle = couponDialogTitle2ch[mode] || "时长卡";

    this.initCouponForm(mode);

    this.dialogFormVisible = true;
  }

  private currentCouponItem: any = null;

  private couponDialogMode: string = "";

  private async initCouponForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-coupon") {
      // 编辑
      await this.getMoreVenueList(true);

      // await this.refreshCouponTimeDurations();

      await this.refreshTableClass();

      const currentCouponItem = this.currentCouponItem;

      const {
        id,
        name,
        valid_time,
        value,
        shop_type,
        third_party_id,
        shop_name,
        shop_id,
        // coupons_time_id,
        begin,
        end,
        time_type,
        limit_day,

        pool_table_category_ids,
        quantity = '',
      } = currentCouponItem || {};

      if (name) {
        forms.name = name;
      }

      if (valid_time) {
        forms.expired_days = valid_time.toString();
      }

      if (value) {
        forms.timeDuration = value.toString();
      }

      if (shop_type) {
        forms.shop_type = shop_type
      }

      if (third_party_id) {
        forms.kuaishouId = third_party_id.toString();
      }

      if (typeof quantity == 'number') {
        forms.quantity = quantity.toString()
      }

      if (typeof time_type == 'number') {
        forms.time_type = time_type
      }

      if (time_type == 1) {
        forms.limit_day = limit_day.split(',').map((day: string) => Number(day))

        if (begin) {
          forms.begin = begin
        }

        if (end) {
          forms.end = end
        }
      }

      if (pool_table_category_ids) {
        forms.pool_table_category_ids = pool_table_category_ids
          .split(",")
          .map((id: string) => Number(id));
      }

      const venues: any[] = []

      const handleAddExtraVenue = (venues: any[], id: any, name: any) => {
        if (name && typeof id == 'number') {
          let existVenue = this.venueList.find((venue) => venue.id == id);

          if (!existVenue) {
            existVenue = {
              id,
              name,
            };
            this.venueList.push(existVenue);
          }

          venues.push(existVenue)
        }

        console.log(this.venueList, JSON.stringify(this.venueList))
      }

      handleAddExtraVenue(venues, shop_id, shop_name)

      // if (shop_name && typeof shop_id == "number") {
      //   console.log(shop_id, "shop_id");

      //   console.log(this.venueList, "this.venueList");
      //   let existVenue = this.venueList.find((venue) => venue.id == shop_id);

      //   if (!existVenue) {
      //     existVenue = {
      //       id: shop_id,
      //       name: shop_name,
      //     };
      //     this.venueList.push(existVenue);
      //   }

      //   forms.venue = existVenue;
      // }
      forms.venues = venues;

      forms.id = id;
    }

    console.log(forms, "forms");

    console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditCouponDialog(couponItem: any) {
    this.currentCouponItem = couponItem;

    this.handleWakeCouponDialog("edit-coupon");
  }

  private handleDeleteCoupon(couponItem: any) {
    this.$confirm("确认删除该卡券？", '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.couponsDyManageApi.delteKuaishouCouponItem({
        id: couponItem.id
      }, () => {
        this.getCoupons();
      }, () => {

      })
    })
  }

  private venueList: any[] = [
    // {
    //   id: 1,
    //   label: '测试'
    // },
    // {
    //   id: 2,
    //   label: '测试2'
    // }
  ];

  private searchVenueLoading: boolean = false;

  private remoteGetVenueQueryString: string = "";

  private remoteSearchVenueTimer: any = null;

  private searchVenuePageOption: any = {};

  private initRemoteGetVenuePageOption() {
    this.searchVenuePageOption = {
      ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    };
  }

  private getSearchVenueParams() {
    const searchVenuePageOption = this.searchVenuePageOption;

    const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

    const { page, limit } = searchVenuePageOption;

    const params: any = {
      page,
      limit,
    };

    if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
      params.name = remoteGetVenueQueryString.trim();
    }

    return params;
  }

  private getMoreVenueList(refresh: boolean = true) {
    return new Promise((resolve: Function, reject: Function) => {
      if (refresh) {
        this.initRemoteGetVenuePageOption();
      }

      const params = this.getSearchVenueParams();

      this.couponsDyManageApi.searchVenueList(
        params,
        (res: any) => {
          console.log(res, "res");

          const venueList: any[] = res.data || [];

          this.venueList = refresh
            ? venueList
            : this.venueList.concat(venueList);

          console.log(this.venueList, JSON.stringify(this.venueList))
          if (Array.isArray(res.data) && res.data.length == 0) {
            this.searchVenuePageOption.isRearchBottom = true;
          }
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          resolve();
        },
        () => {
          this.searchVenueLoading = false;

          this.addVenueListLoading = false;

          reject();
        }
      );
    });
  }

  // 第一次搜索 无关键字
  private handleFirstGetVenueList() {
    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 清空搜索
  private handleClearGetVenueList() {
    this.remoteGetVenueQueryString = "";

    this.searchVenueLoading = true;

    this.getMoreVenueList(true);
  }

  // 远程搜索
  private remoteGetVenueMethod(query: string) {
    query = query.trim ? query.trim() : "";

    if (query !== "") {
      this.remoteGetVenueQueryString = query;
      clearTimeout(this.remoteSearchVenueTimer);

      this.remoteSearchVenueTimer = setTimeout(() => {
        this.searchVenueLoading = true;

        this.getMoreVenueList();
        // searchStu((res) => {
        //   const {
        //     students,
        //     total_count
        //   } = res || {}

        //   this.remoteAllStudents = [
        //     ...(students || [])
        //   ]

        //   this.remoteStudentTotalCount = total_count || 0

        //   this.remoteGetStudentLoading = false
        // }, {
        //   kw: query,
        //   ...this.getRemoteGetStudentPageOptionParams()
        // }, () => {
        //   this.remoteGetStudentLoading = false
        // })
      }, 300);
      // setTimeout(() => {
      //   this.remoteGetStudentLoading = false;
      //   this.options = this.list
      // }, 200);
    } else {
      this.remoteGetVenueQueryString = "";
      this.venueList = [];
    }
  }

  private addVenueListLoading: boolean = false;

  private remoteAddVenueMethod() {
    if (this.addVenueListLoading) return;
    const searchVenuePageOption = this.searchVenuePageOption;

    const { isRearchBottom } = searchVenuePageOption;

    if (isRearchBottom) return;

    this.searchVenuePageOption.page++;

    this.addVenueListLoading = true;

    this.getMoreVenueList(false);
  }

  // // 原价限制
  // private handleLimitOriginPrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     2
  //   );
  // }

  // // 售价限制
  // private handleLimitSlaePrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     2
  //   );

  // }

  // 有效期
  private handleLimitExpiredDays(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxExpiredDays).toString();
        this.$set(this.forms, "expired_days", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "expired_days", new_val);
      }
    );
  }

  // 时长
  private handleLimitTimeDuration(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
        this.$set(this.forms, "timeDuration", limit_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "timeDuration", new_val);
      }
    );
  }

  private handleLimitSingleMaxCount(val: string) {
    Util.limitIntegetInput(
      val,
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      },
      (new_val: string) => {
        this.$set(this.forms, "maxLimitCount", new_val);
      }
    );
  }

  private sumbitCouponLoading: boolean = false;

  // 提交卡券配置
  private handleSumbitCoupon() {
    const couponDialogMode = this.couponDialogMode;

    const params = this.getCouponFormsParams(couponDialogMode);

    console.log(params, "params");
    if (params === false) return;

    this.sumbitCouponLoading = true;
    switch (couponDialogMode) {
      case "new-coupon":
        this.couponsDyManageApi.addKuaishouCoupons(
          params,
          () => {
            this.getCoupons();
            this.dialogFormVisible = false;
            this.sumbitCouponLoading = false;
          },
          () => {
            this.sumbitCouponLoading = false;
          }
        );
        break;
      case "edit-coupon":
        this.couponsDyManageApi.updateKuaishouCoupons(
          params,
          () => {
            this.getCoupons();
            this.dialogFormVisible = false;
            this.sumbitCouponLoading = false;
          },
          () => {
            this.sumbitCouponLoading = false;
          }
        );
        break;
    }
  }

  private getCouponFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // kuaishouId,
      // timeDuration
      // coupons_time_id,
      pool_table_category_ids,
      time_type,
      begin,
      end,
      limit_day,
      venues,
    } = forms || {};

    const keys = ["name", "shop_type", "expired_days", "timeDuration", "kuaishouId", "quantity"];

    const key2EndKey: any = {
      name: "name",
      shop_type: "shop_type",
      expired_days: "valid_time",
      timeDuration: "value",
      kuaishouId: "third_party_id",
      quantity: "quantity",
    };

    const key2ErrMsg: any = {
      name: "名称不能为空",
      expired_days: "有效期天数不能为空",
      timeDuration: "时长不能为空",
      shop_type: "卡券类型不能为空",
      expired_days_0: "有效天数不能为零",
      timeDuration_0: "时长不能为零",
      kuaishouId: "快手ID不能为空",
      quantity: "团购核验次数不能为空",
    };

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        if (errMsg) {
          showErrorMessage(errMsg);

          return false;
        }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        showErrorMessage(key2ErrMsg[key]);

        return false;
      }
    }

    // if (typeof coupons_time_id == "number") {
    //   params.coupons_time_id = coupons_time_id;
    // }

    if (
      Array.isArray(pool_table_category_ids) &&
      pool_table_category_ids.length
    ) {
      params.pool_table_category_ids = pool_table_category_ids.join(",");
    }

    if (typeof time_type == 'number') {
      params.time_type = time_type
    }

    if (time_type == 1) {
      if (begin && end) {
        params.begin = begin

        params.end = end
      } else {
        showErrorMessage("请选择使用时间范围");
        return false;
      }

      if (Array.isArray(limit_day) && limit_day.length > 0) {
        params.limit_day = limit_day.join(',')
      } else {
        showErrorMessage("请至少选择一个日期");
        return false;
      }
    }

    if (venues && venues.length > 0) {
      params.shop_id = venues.map((venue: any) => venue.id).join(',');
    } else {
      showErrorMessage("请至少选择一个店铺");
      return false;
    }

    if (mode == "edit-coupon") {
      params.id = id;
    }

    return params;

    // if (name) {
    //   params.name = name
    // } else {
    //   showErrorMessage('名称不能为空')
    // }

    // if (expired_days) {
    //   params.valid_time = expired_days
    // }

    // if (timeDuration) {
    //   params.value = timeDuration
    // }

    // if (kuaishouId) {

    // }
  }

  //获取优惠券列表
  private getCoupons(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    this.couponsDyManageApi.getKuaishouCoupons(
      { ...this.getCouponParams() },
      (res: any) => {
        // res.data.list
        this.tableData = res.data.data;
        this.tablePage.page = res.data.current_page;
        this.tablePage.prepage = res.data.per_page;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.total;
      },
      () => {}
    );
  }

  private getCouponParams(): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { shop_name, name, third_party_id } = form;

    if (shop_name) {
      params.shop_name = shop_name;
    }

    if (name) {
      params.name = name
    }

    if (third_party_id) {
      params.third_party_id = third_party_id
    }

    if (page) {
      params.page = page;
    }

    if (prepage) {
      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  // private getOptions(): void {
  //   this.couponsDyManageApi.selectCoupons(
  //     (res: any) => {
  //       this.options = res.data.list;
  //       this.selectPage.page = res.data.page;
  //       this.selectPage.prepage = res.data.prepage;
  //       this.selectPage.totalPage = res.data.totalPage;
  //       this.selectPage.count = res.data.count;
  //       this.subId = "";
  //     },
  //     {
  //       ...this.selectPage,
  //       superpositionJoin: this.forms.superpositionJoin,
  //       id: this.subId,
  //     }
  //   );
  // }

  // @Watch("dialogFormVisible")
  // private changeDialog(): void {
  //   if (this.dialogFormVisible) {
  //     this.getOptions();
  //   }
  // }

  // private changeSuperpositionType(): void {
  //   this.$set(this.forms, "superpositionType", "2");

  //   // if (this.forms.type == 2) {

  //   //   this.forms.superpositionType = "2";
  //   // }
  // }

  // private changeMaxType(): void {
  //   // const MaxType=this.forms.maxType
  //   // this.$set(this.forms, "maxType", MaxType);
  //   this.$forceUpdate();
  // }

  // private changeMinType(): void {
  //   // if (this.forms.type == 2) {
  //   //   this.forms.minType = "0";
  //   // }
  //   this.$forceUpdate();
  // }

  // private changeType(): void {
  //   if (this.forms.type == 2) {
  //     this.forms.superpositionType = "2";
  //     // this.forms.minType = "0";
  //   }
  //   if (this.forms.type != 3) {
  //     this.forms.minMoneyLimit = 0;
  //   }
  //   this.$forceUpdate();
  // }

  // private changeforms(): void {
  //   // console.log(this.forms.time)
  //   this.$forceUpdate();
  // }
}
